import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getToken = () => {
    const Token = localStorage.getItem('AccessToken');
    return !!Token ? Token : '';
};

export const removeToken = () => {
    localStorage.removeItem("AccessToken");
  };


const deletUserAccount = async (id, reason, password) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/account-deleted`, 
      { id, reason, password },
      {
        headers: {
          'ngrok-skip-browser-warning': 'true',
          'Authorization': JSON.parse(getToken())
        },
      }
    );
    
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to delete account');
  }
};

const getUserDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get-user`, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
          'token': `${JSON.parse(getToken())}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch user details');
    }
};
  

export { deletUserAccount , getUserDetails }