// import { Route, Routes ,Router } from 'react-router-dom';
// // import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import './App.css';
// import Home from './Pages/Home';
// import PrivacyPolicy from './Pages/PrivacyPolicy';
// import TermsOfCondition from './Pages/TermsOfCondition';
// import AccountDeleting from './Pages/AccountDeleting.js';
// import Login from './Pages/Login.js';
// import { QueryClient, QueryClientProvider } from 'react-query';
// import 'react-toastify/dist/ReactToastify.css';
// import { SkeletonTheme } from 'react-loading-skeleton';
// import { ToastContainer } from 'react-toastify';
// import ProtectedRoutes from './hooks/ProtectedRoutes';



// const queryClient = new QueryClient(
//   {
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//     },
//   },
// }
// );

// const App = () => {
//   return (
//     <>
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//         <Route path="/terms-of-use" element={<TermsOfCondition />} />
//         <Route path="/account-deleting" element={<AccountDeleting />} />
//         {/* <Route path="/Login" element={<Login />} /> */}
//       </Routes>
//       <SkeletonTheme baseColor="#F3F4F8" highlightColor="#F9F9FB" borderRadius="2px">
//       <QueryClientProvider client={queryClient}>
//         <ToastContainer />
//         <Router>
//           <Routes>
//             <Route path="/login" element={<Login />} />
//             <Route path="/" element={
//               <ProtectedRoutes redirectTo="/login">
//                 <Home />
//               </ProtectedRoutes>} />
//             <Route path="*" element={
//               <ProtectedRoutes redirectTo="/login">
//                 <Home />
//               </ProtectedRoutes>} />
//           </Routes>
//         </Router>
//       </QueryClientProvider>
//     </SkeletonTheme>
//     </>
//   );
// };

// export default App;
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Only import one Router
import './App.css';
import Home from './Pages/Home';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfCondition from './Pages/TermsOfCondition';
import Login from './Pages/Login.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
// import ProtectedRoutes from './hooks/ProtectedRoutes';
import AccountDeleting from './Pages/AccountDeleting';


// QueryClient instance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <SkeletonTheme baseColor="#F3F4F8" highlightColor="#F9F9FB" borderRadius="2px">
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        {/* Only one Router wrapping the entire application */}
        {/* <Router> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfCondition />} />
            <Route path="/account/delete" element={<Login />} />
            <Route path="/account/delete/confirm" element={<AccountDeleting />} />
            {/* <Route
              path="/"
              element={
                <ProtectedRoutes redirectTo="/login">
                  <Home />
                </ProtectedRoutes>
              }
            />
            <Route
              path="*"
              element={
                <ProtectedRoutes redirectTo="/login">
                  <Home />
                </ProtectedRoutes>
              }
            /> */}
          </Routes>
        {/* </Router> */}
      </QueryClientProvider>
    </SkeletonTheme>
  );
};

export default App;
