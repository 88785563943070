import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

// admin@tulagram.com
// Tulagram@2023

const signIn = async (credentials) => {
  return axios.post(`${BASE_URL}/login`, credentials);
};

export { signIn }