import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, removeToken } from "../api/home";
import { useDeletUserAccount, useGetUser } from "../hooks/hooks";
import { jwtDecode } from "jwt-decode";
import { Header } from '../components/Header';
import EyeIcon from "../images/eye.svg";
import closeEyeIcon from "../images/EyeClose.svg";

const AccountDeleting = () => {

  const [showPassword, setShowPassword] = useState(false);
  const [reason, setReason] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();

  const {
    mutateAsync: deleteAccountMutation,
    isLoading,
    error: mutationError,
  } = useDeletUserAccount();


  const {
    mutate: getUserMutation,
    isLoading: userLoading,
    data,
    error: userError,
  } = useGetUser();


  useEffect(() => {
    
    if (!getToken() && !data) { 
      navigate("/account/delete");
    }
    getUserMutation();
  }, [navigate, getUserMutation]);


  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    if (!reason || !password) {
      setError("Please provide all required fields.");
      return;
    }
    try {
      const decodedToken = jwtDecode(getToken());
      const userId = decodedToken.id;
      const result = await deleteAccountMutation({
        id: userId,
        reason,
        password,
      });
      if (result.success) {
        setShowSuccess(true);
          removeToken();
          setTimeout(()=>{
              navigate("/account/delete");
          },2000)
      } else {
        setError(result.message || "Failed to delete account");
      }
    } catch (err) {
      console.error("Delete error:", err);
      setError(err.message || "Failed to delete account");
    }
  };


  return (
    <div className="min-h-screen bg-gray-100  sm:px-6 lg:px-8">
                  <Header />

      <div className="max-w-5xl mx-auto mt-10">
        {/* Header Section */}
        <div className="bg-white shadow-sm rounded-lg mb-6 p-6">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-semibold text-gray-800">
              User Profile
            </h1>
            <span className="px-4 py-1 bg-blue-600 text-white rounded-full text-sm">
              Active
            </span>
          </div>
        </div>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Left Column - Personal Info */}
          <div className="bg-white shadow-sm rounded-lg p-6">
            <div className="flex flex-col items-center">
              {data && (
                <>
                  <div className="w-24 h-24 bg-gray-200 rounded-full mb-4 flex items-center justify-center">
                    <span className="text-3xl text-gray-600">
                      {data?.name?.[0]}
                    </span>
                  </div>
                  <h2 className="text-xl font-medium text-gray-900">
                    {data?.name}
                  </h2>
                  <p className="text-gray-500 text-sm mt-1">{data?.email}</p>
                </>
              )}
            </div>
          </div>

          {/* Right Column - Account Actions */}
          <div className="bg-white shadow-sm rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Account Actions
            </h3>
            <div className="space-y-4">
              <button
                className="w-full px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md transition-colors duration-200"
                onClick={() => setShowModal(true)}
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
            {showSuccess ? (
              <div className="text-center py-8">
                <div className="mb-4 text-green-500">
                  <svg
                    className="w-16 h-16 mx-auto"
                    fill="none"
                    strokeCurrentColor
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-medium text-gray-900 mb-2">
                  Account Successfully Deleted
                </h3>
                {/* <p className="text-gray-600">
                  You will be redirected to the login page...
                </p> */}
              </div>
            ) : (
              <>
                <div className="mb-5">
                  <h3 className="text-red-600 text-xl font-semibold flex items-center gap-2">
                    <span>⚠️</span> Warning
                  </h3>
                  <p className="text-gray-700 mt-2">
                    Are you sure you want to delete your account?
                  </p>
                </div>
                <form onSubmit={handleDeleteAccount} className="space-y-4">
                  <div>
                    <label
                      htmlFor="reason"
                      className="block text-gray-700 mb-1"
                    >
                      Reason for leaving:
                    </label>
                    <textarea
                      id="reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      placeholder="Why are you leaving?"
                      required
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-black  focus:outline-none resize-none"
                    />
                  </div>
                  <div className="relative w-full">
                    <label
                      htmlFor="password"
                      className="block text-gray-700 mb-1 font-medium"
                    >
                      Confirm Password:
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="w-full px-3 py-2 pr-10 border rounded-md focus:ring-2 focus:ring-black focus:outline-none text-gray-900"
                        placeholder="Enter your password"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 focus:outline-none"
                      >
                        <img
                          src={showPassword ? closeEyeIcon : EyeIcon}
                          alt={showPassword ? "Hide password" : "Show password"}
                          className="w-5 h-5 text-gray-600 hover:text-gray-900"
                        />
                      </button>
                    </div>
                  </div>

                  {error && (
                    <p className="text-red-500">
                      Invalid password. Please try again.
                    </p>
                  )}
                  <div className="flex justify-end gap-3 mt-6">
                    <button
                      type="button"
                      onClick={() => setShowModal(false)}
                      className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-200"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isLoading ? "Deleting..." : "Delete Account"}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountDeleting;
