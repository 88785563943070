import React, { useRef } from 'react';
import GooglePlayBadge from '../images/google-play-badge.svg';
import Logo from '../images/Tulagram_logo.svg';
import Frame1 from '../images/MobileFrame.png';
// import Frame2 from '../images/Frame 2.png';
import Step1 from '../images/iPhone 14 Pro Max.png';
import Step2 from '../images/iPhone 14 Pro Max-1.png';
import Step3 from '../images/iPhone 14 Pro Max-2.png';
import Step4 from '../images/iPhone 14 Pro Max-3.png';
import Step5 from '../images/iPhone 14 Pro Max-4.png';
// import Step6 from '../images/iPhone 14 Pro Max-5.png';
import RightArrow from '../images/RightRed.svg';
import LeftArrow from '../images/LeftRed.svg';
import RightArrowGray from '../images/Right.svg';
import LeftArrowGray from '../images/Left.svg';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const swiperRef = useRef();

  // const isBeginning = () => {
  //   return swiperRef.current?.isBeginning ?? false;
  // };

  const isEnd = () => {
    return swiperRef.current?.isEnd ?? false;
  };
  const navigate = useNavigate();

  return (
    <div className="w-[100%] h-[100vh] max-h-[1080px] max_md:h-auto left-container">
      <div className="hidden max_md:block center-logo my-[40px]">
        <img src={Logo} alt="" />
      </div>
      <div className="h-[100vh] max_md:h-auto max-h-[1080px] m-auto max-w-[1420px] max_xl:max-w-[1080px] max_sm:px-[0px] flex justify-between max_md:flex-col-reverse">
        <div className="w-[30%] flex-col justify-between mt-[40px] max_xl:w-[37%] max_md:w-[100%] max_md:flex-col max_md:justify-center max_md:px-[100px] max_sm:px-[40px] max_md:mb-[80px] max_sm:mt-5 ">
          <div className="h-[calc(100vh-120px)] max_md:h-auto max-h-[1080px] max_md:max-h-auto max_1024:max-h-[768px]">
            <div className="max_md:hidden block">
              <img src={Logo} alt="" />
            </div>
            <div className="heading-text text-[90px] max_md:text-[70px] max_sm:text-[44px] mt-[64px] max_xl:mt-[45px] max_sm:mt-[35px] leading-[120px] max_1620:leading-[108px] max_xl:leading-[90px] max_xl:text-[76px] max_sm:leading-[60px] w-[130%]">
              Want to send Tulips?
            </div>
            <div className="title-text text-[20px] mt-[30px] max_xl:mt-[25px]">
            Send Tulips through our app to your loved ones instantly. Please Sign up and keep going.
            </div>
            <div className="mt-[40px] max-w-max">
              <a
                href="https://play.google.com/store/apps/details?id=com.tulip.tulipapp"
                target="_blank"
              >
                <img src={GooglePlayBadge} alt="google_badge" />
              </a>
            </div>
          </div>
          <div className="h-[45px] flex items-center gap-4 max_sm_328:flex-col max_sm_328:gap-0 max_sm_328:mt-3 max_sm_328:items-start">
            <div
              className="title-text text-[20px] max_sm_400:text-[16px]  text-[#E23844] max-w-max cursor-pointer"
              onClick={() => navigate('/privacy-policy')}
            >
              Privacy Policy
            </div>
            <div
              className="title-text text-[20px] max_sm_400:text-[16px]  text-[#E23844] cursor-pointer max-w-max"
              onClick={() => navigate('/terms-of-use')}
            >
              Terms & Conditions
            </div>
          </div>
        </div>
        <div className="relative w-[64%] max_1620:w-[60%] right-container max_md:w-[100%] justify-center items-center flex flex-col">
          <div className="z-40 w-[35%] ml-[20%] max_xl:ml-[38%] max_xl:w-[46%] max_md:ml-0">
            <Swiper
              modules={[Pagination]}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              className="w-[100%]"
            >
              <SwiperSlide>
                <img src={Step1} alt="back" className="max_xl:w-[100%]" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Step2} alt="back2" className="max_xl:w-[100%]" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Step3} alt="back2" className="max_xl:w-[100%]" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Step4} alt="back2" className="max_xl:w-[100%]" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Step5} alt="back2" className="max_xl:w-[100%]" />
              </SwiperSlide>
              {/* <SwiperSlide>
                <img src={Step6} alt="back2" className="max_xl:w-[100%]" />
              </SwiperSlide> */}
            </Swiper>
          </div>
          <div className="hidden max_md:block absolute w-[60%] right-0 top-0 z-10">
            <img src={Frame1} alt="Fram" />
          </div>

          <div className="flex justify-center items-center w-[35%] ml-[20%] max_xl:ml-[38%] max_md:ml-0 gap-2 z-50 mt-[10px]">
            <div
              className={`cursor-pointer ${isEnd() ? 'inactive' : ''}`}
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <img
                src={LeftArrowGray}
                alt=""
                onMouseOver={(e) => (e.currentTarget.src = LeftArrow)}
                onMouseOut={(e) => (e.currentTarget.src = LeftArrowGray)}
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <img
                src={RightArrowGray}
                alt=""
                onMouseOver={(e) => (e.currentTarget.src = RightArrow)}
                onMouseOut={(e) => (e.currentTarget.src = RightArrowGray)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
