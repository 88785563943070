import { signIn } from "../api/auth";
import { useMutation } from "react-query";
import { deletUserAccount, getUserDetails } from "../api/home";

export const useSignIn = () => {
  return useMutation({
    mutationFn: (data) => signIn(data),
  });
};

// export const useDeletUserAccount = () => {
//   return useMutation(async ({ id, reason, password }) => {
//     const response = await deletUserAccount(id, reason, password);
//     console.log(response, "responseresponse");
    
//     if (!response.ok) {
//       throw new Error("Error deleting account");
//     }
//     return response.data;
//   });
// };
export const useDeletUserAccount = () => {
  return useMutation(async ({ id, reason, password }) => {
    const data = await deletUserAccount(id, reason, password);
    return data;
  });
};

export const useGetUser = () => {
  return useMutation(async () => {
    const response = await getUserDetails();
    if (!response.success) {
      throw new Error("Error fetching user details");
    }
    return response.data;
  });
};
