import { toast } from 'react-toastify';

export const showSuccessToast = (message) => {
    toast.success(message, {
        hideProgressBar: true,
        closeButton: false
    });
};

export const showErrorToast = (message) => {
    toast.error(message, {
        hideProgressBar: true,
        closeButton: false
    });
};