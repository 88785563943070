import React, { useState, useEffect } from "react";
// import InputFields from "../components/InputFields";
import { useNavigate } from "react-router-dom";

import LogoWithName from "../images/logowithname.svg";
import BottomFlower from "../images/bottomflower.svg";
// import leftPanel from '../images/login-left.svg'

import Frame from "../images/Frame.svg";
import { useSignIn } from "../hooks/hooks";
import { showErrorToast } from "../components/common";
import { getToken } from "../api/home";
import EyeIcon from "../images/eye.svg";
import closeEyeIcon from "../images/EyeClose.svg";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (getToken()) {
      navigate("/account/delete/confirm");
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState({ email: "", password: "" });
  const [Error, setError] = useState({ email: "", password: "" });
  const { mutate: loginUser, isLoading } = useSignIn();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value.toString().trim(),
    });
    setError({ ...Error, [name]: "" });
  };

  const logInUser = (inputValue) => {
    loginUser(inputValue, {
      onSuccess: (res) => {
        if (res.status === 200 && res?.data?.token) {
          localStorage.setItem("AccessToken", JSON.stringify(res?.data?.token));
          if (getToken()) {
            navigate("/account/delete/confirm");
          }
          return;
        }
        if (!res?.success) {
          showErrorToast("Invalid username and password");
          return;
        }
      },
      onError: (res) => {
        if (res?.response?.status === 400) {
          showErrorToast("Invalid username and password");
        } else {
          showErrorToast("Something went wrong!!!");
        }
      },
    });
  };

  const onSubmit = () => {
    if (inputValue.email.toString().trim() === "") {
      setError({ ...Error, email: "Please enter email" });
      return;
    }
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
    if (!emailRegex.test(inputValue.email)) {
      setError({ ...Error, email: "Invalid Email" });
      return;
    }
    if (inputValue.password.toString().trim() === "") {
      setError({ ...Error, password: "Please enter password" });
      return;
    }
    logInUser(inputValue);
  };

  return (
    <>
      <div className="grid grid-cols-2 max_720:grid-cols-1 max_768:grid-cols-1 h-screen select-none">
        <div
          className="max_720:hidden max_768:hidden relative flex justify-center items-center"
          style={{
            background: "linear-gradient(92deg, #FF7882 0%, #E23844 100%)",
            boxShadow: "0px 4px 10px 0px rgba(226, 56, 68, 0.20)",
          }}
        >
          <div className="absolute h-[100vh] left-0">
            <img
              draggable={false}
              className="h-[100%] w-[100%]"
              src={Frame}
              alt=""
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              className="w-[80%] mt-[-30%]"
              draggable={false}
              src={LogoWithName}
              alt=""
            />
          </div>
          <div className="absolute bottom-0 ml-[20px] left-0">
            <img
              className="max_1024:w-[75%] max_1536:w-[80%]"
              draggable={false}
              src={BottomFlower}
              alt=""
            />
          </div>
        </div>
        <div className="bg-white flex flex-col items-center justify-center">
          <div className="font-[800] text-[40px] text-[#E23844]">Login</div>
          <div className="w-full flex flex-col justify-center items-center mt-[87px] max_sm:px-[20px] ">
            <div className="max_sm:w-[100%]">
              <input
                name="email"
                type="email"
                placeholder="EMAIL ADDRESS"
                disabled={isLoading}
                onChange={handleChange}
                value={inputValue?.email}
                className="text-input font-[500] max_sm:w-[100%] w-[420px] h-[50px] border border-[#E5E7F2] text-[14px] font-[300] text-[#000] px-[12px] rounded"
              />
              {Error?.email && (
                <div className="text-red-500 text-[14px]">{Error?.email}</div>
              )}
            </div>
            <div className="mt-[35px] max_sm:w-[100%]">
              <label
                htmlFor="password"
                className="text-[14px] font-[300] text-[#000]"
              ></label>
              <div className="relative">
                <input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="PASSWORD"
                  disabled={isLoading}
                  onChange={handleChange}
                  value={inputValue?.password}
                  className="text-input font-[500] max_sm:w-[100%] w-[420px] h-[50px] border border-[#E5E7F2] text-[14px] font-[300] text-[#000] px-[12px] pr-[40px] rounded"
                />
                {/* Toggle button with Eye icon */}
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-4 top-[50%] transform -translate-y-[50%] focus:outline-none"
                >
                  <img
                    src={showPassword ? closeEyeIcon : EyeIcon}
                    alt={showPassword ? "Hide password" : "Show password"}
                    className="w-[20px] h-[20px]"
                  />
                </button>
              </div>
              {Error?.password && (
                <div className="text-red-500 text-[14px]">
                  {Error?.password}
                </div>
              )}
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onSubmit();
              }}
              disabled={isLoading}
              className="mt-[60px] max_sm:w-[100%] w-[420px] h-[46px] rounded-[2px] bg-[#E23844] text-[16px] font-[600] text-white"
            >
              {isLoading ? (
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              ) : (
                "Login"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
